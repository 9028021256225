import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import {baseURL} from "@/utils/helper";
import {checkPermission,checkFeatureDisplayed} from "@/core/helpers/general";
import "@/core/plugins/prismjs";
import "bootstrap";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import ApiAxiosService from "@/core/services/ApiAxiosService";

import mitt from 'mitt';                 // Import mitt
import {getMessaging, getToken} from "firebase/messaging";
import {onMessage} from "@firebase/messaging";
import {APIs} from "@/store/enums/ApiEnums";
import {Actions} from "@/store/enums/StoreEnums";
// import VueLazyload from 'vue-lazyload'

import initializeApp = firebase.initializeApp;
const emitter = mitt();                   // Initialize mitt
const app = createApp(App);

let user = JSON.parse(<string>localStorage.getItem("user"));
const google_maps_key = user?.google_maps_key;

app.use(VueGoogleMaps, {
    load: {
        key: google_maps_key,
        libraries: "places",
    },
});


app.use(store);
app.use(router);
app.use(ElementPlus);
// app.use(VueLazyload);

app.mixin({
    methods: {
        formattedNum(Num) {
            return parseFloat(Num).toFixed(2);
        },
        isFeatureDisplayed(item , actionCode = null) {
            return checkFeatureDisplayed(item,actionCode)
        },
        baseURL
    }
})

ApiService.init(app);
ApiAxiosService.init();
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.provide('emitter', emitter);
app.mount("#app");
const commentNode = function (el, vnode) {
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined
    })
    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    if (vnode?.data?.directives)
        vnode.data.directives = undefined
    if (vnode.componentInstance)
        vnode.componentInstance.$el = comment
    if (el.parentNode)
        el.parentNode.replaceChild(comment, el)
}

app.directive('can', {

    mounted(el, binding, vnode, prevVnode) {
        if (!checkPermission(binding.value)) {
            commentNode(el, vnode);
        }
    },
});


const getFirebaseToken = async () => {

    user = JSON.parse(<string>localStorage.getItem("user"));
    if(!user) return ;

    // Initialize Firebase
    let firebaseApp;
    try {
        firebaseApp = initializeApp(user.firebase_web_keys);
        const messaging = getMessaging(firebaseApp);
        const sw = await window.navigator.serviceWorker.register('firebase-messaging-sw.js?firebase_web_keys='+(JSON.stringify(user.firebase_web_keys)))
        const token = await getToken(messaging, {
            serviceWorkerRegistration: sw
        })
        // const token = await getToken(messaging)
        onMessage(messaging, function (data) {
            emitter.emit('firebase_notification' , data)
        });

        return token;
    }catch (e) {
        if(e.code == "app/duplicate-app"){
            window.location.reload();
        }
        return null;
    }

}
app.provide('getFirebaseToken', getFirebaseToken);

if (user) {
    getFirebaseToken().then(function (token){
        store.dispatch(Actions.SET_FCM_TOKEN, token);
    });
}



