// export const BASE_URL = `http://127.0.0.1:8082`;
// export const BASE_URL = `http://127.0.0.1:8091`;

export const BASE_URL = `https://sanamfit.com`;
// export const BASE_URL = `https://${location.hostname}`;
export const API_END_POINT = `${BASE_URL}/admin/api`;

export const APIs = {
    LOGIN: {
        login_from_platform: '/login-from-platform'
    },
    PROFILE: {
        profile: `/profile`,
        change_password: `/change-password`,

    },
    ADMIN: {
        list: `/admins`,
        create: `/admins/create`,
        store: `/admins`,
        edit: (id) => `/admins/${id}/edit`,
        update: (id) => `/admins/${id}`,
        show: (id) => `/admins/${id}`,
        delete: (id) => `/admins/${id}`,
        filter_options: `/admins-list/filter-options`,
        set_fcm_token: 'admin/set-fcm-token',
        notifications: 'admin/notifications',
        make_read: (id) => `admin/notifications/${id}/make-read`,

    },
    DASHBOARD: {
        home: `/dashboard`,
        loyalty: `/loyalty-dashboard`,
        loyalty_export: `${BASE_URL}/admin/api/loyalty-dashboard/export`,
    },
    COUNTRY: {
        list: `/countries`,
        filter_options: `/countries-list/filter-options`,
        actions: `/countries-list/action`,
        // cities: (id: any) => `/countries/${id}/cities`,
        change_order: (id: any) => `/countries-list/${id}/change-order`,
    },

    PAYMNET_METHOD: {
        list: `/payment_methods`,
        filter_options: `/payment_methods-list/filter-options`,
        actions: `/payment_methods-list/action`,
        change_status: (id: any) => `/payment_methods-list/${id}/change-status`,
    },

     ORDER: {
        list: `/orders`,
        show: (id) => `/orders/${id}`,
        filter_options: `/orders-list/filter-options`,
        all: `/orders-list/all`,
        change_status: (id) => `/orders/${id}/change-status`,
     
    },

    CITY: {
        list: `/cities`,
        create: `/cities/create`,
        store: `/cities`,
        edit: (id: any) => `/cities/${id}/edit`,
        update: (id: any) => `/cities/${id}`,
        show: (id: any) => `/cities/${id}`,
        delete: (id: any) => `/cities/${id}`,
        filter_options: `/cities-list/filter-options`,
        actions: `/cities-list/action`,
    },
    LANGUAGE: {
        list: `/languages`,
        create: `/languages/create`,
        store: `/languages`,
        edit: (id: any) => `/languages/${id}/edit`,
        update: (id: any) => `/languages/${id}`,
        show: (id: any) => `/languages/${id}`,
        delete: (id: any) => `/languages/${id}`,
        filter_options: `/languages-list/filter-options`,
    },
    CATEGORY: {
        list: `/categories`,
        create: `/categories/create`,
        store: `/categories`,
        edit: (id: any) => `/categories/${id}/edit`,
        delete: (id: any) => `/categories/${id}`,
        update: (id: any) => `/categories/${id}`,
        filter_options: `/categories-list/filter-options`,
        show: (id: any) => `/categories/${id}`,
        fetch: `/fetch-categories`,
    },
    SUB_CATEGORY: {
        list: `/sub-categories`,
        create: `/sub-categories/create`,
        store: `/sub-categories`,
        edit: (id: any) => `/sub-categories/${id}/edit`,
        delete: (id: any) => `/sub-categories/${id}`,
        update: (id: any) => `/sub-categories/${id}`,
        filter_options: `/sub-categories-list/filter-options`,
        show: (id: any) => `/sub-categories/${id}`,
        fetch: `/fetch-sub-categories`,
    },
    PRODUCT: {
        list: `/products`,
        create: `/products/create`,
        store: `/products`,
        edit: (id: any) => `/products/${id}/edit`,
        update: (id: any) => `/products/${id}`,
        filter_options: `/products-list/filter-options`,
        sub_categories: `/products-list/sub-categories`,
        show: (id: any) => `/products/${id}`,
        fetch: `/fetch-products`,
    },

    TAG: {
        list: `/tags`,
        create: `/tags/create`,
        store: `/tags`,
        edit: (id: any) => `/tags/${id}/edit`,
        delete: (id: any) => `/tags/${id}`,
        update: (id: any) => `/tags/${id}`,
        filter_options: `/tags-list/filter-options`,
        show: (id: any) => `/tags/${id}`,
    },
    SOCIAL_MEDIA_SETUP: {
        get: `/social-media`,
        update: `/social-media`,
    },

    NEWS_SUBSCRIPTION: {
        get: `/news-subscriptions`,
        filter_options: `/news-subscriptions-list/filter-options`,
        all: '/news-subscriptions/all',
    },

    TERMS_AND_CONDITION: {
        list: `/terms-and-condition`,
        update: `/terms-and-condition`,
    },
    PRIVACY_POLICY: {
        list: `/privacy-policy`,
        update: `/privacy-policy`,
    },

    PAGE: {
        list: `/pages`,
        create: `/pages/create`,
        store: `/pages`,
        delete: (id: any) => `/pages/${id}`,
        createSection: (id: any) => `pages/create-section/${id}`,
        sections: (id: any) => `/pages/${id}/sections`,
        storeSection: (id: any) => `pages/store-section/${id}`,
        editSection: (id: any) => `pages/edit-section/${id}`,
        updateSection: (id: any) => `pages/update-section/${id}`,
        deleteSection: (id: any) => `pages/delete-section/${id}`,

    },

    SECTION:{
        list: `sections`,
        delete: (id) => `sections/${id}`,
        store: (id) => `sections/${id}`,
        create: (id) => `sections/create/${id}`,
        edit: (id) => `sections/${id}/edit`,
        update: (id) => `sections/${id}`,
        sort: `sections/section-order`,
    },

    FORM: {
        list: `/forms`,
        create: `/forms/create`,
        store: `/forms`,
        edit: (id: any) => `/forms/${id}/edit`,
        delete: (id: any) => `/forms/${id}`,
        update: (id: any) => `/forms/${id}`,
        show: (id: any) => `/forms/${id}`,
        filter_options: `/forms-list/filter-options`,
        allFormRequests: `all-forms`,
        formRequests: (id) => `form-requests/${id}`,
        deleteFormRequest: (id) => `form-requests/${id}`,
       sendEmailFormRequest:(id)=> `form-requests/${id}/send-email`,

    },
    FORM_FIELD: {
        list: `/form-fields`,
        store: `/form-fields`,
        sort: `/form-fields/sort`,
        edit: (id: any) => `/form-fields/${id}/edit`,
        delete: (id: any) => `/form-fields/${id}`,
    },
    GENERAL_CATEGORIES: {
        list: `/general-categories`,
        create: `/general-categories/create`,
        store: `/general-categories`,
        edit: (id: any) => `/general-categories/${id}/edit`,
        delete: (id: any) => `/general-categories/${id}`,
        update: (id: any) => `/general-categories/${id}`,
        show: (id: any) => `/general-categories/${id}`,
        filter_options: `/general-categories-list/filter-options`,
        all: `/general-categories-list`,
    },
    WRITER: {
        list: `/writers`,
        create: `/writers/create`,
        store: `/writers`,
        edit: (id: any) => `/writers/${id}/edit`,
        delete: (id: any) => `/writers/${id}`,
        update: (id: any) => `/writers/${id}`,
        show: (id: any) => `/writers/${id}`,
        filter_options: `/writers-list/filter-options`,
        all: `/writers-list`,
    },
    NEWS: {
        list: `/camel-news`,
        create: `/camel-news/create`,
        store: `/camel-news`,
        edit: (id: any) => `/camel-news/${id}/edit`,
        delete: (id: any) => `/camel-news/${id}`,
        update: (id: any) => `/camel-news/${id}`,
        show: (id: any) => `/camel-news/${id}`,
        filter_options: `/camel-news-list/filter-options`,
    },
    SERVICE: {
        list: `/services`,
        create: `/services/create`,
        store: `/services`,
        edit: (id: any) => `/services/${id}/edit`,
        delete: (id: any) => `/services/${id}`,
        update: (id: any) => `/services/${id}`,
        show: (id: any) => `/services/${id}`,
        filter_options: `/services-list/filter-options`,
    },
    SUB_SERVICE: {
        list: `/sub-services`,
        create: `/sub-services/create`,
        store: `/sub-services`,
        edit: (id: any) => `/sub-services/${id}/edit`,
        delete: (id: any) => `/sub-services/${id}`,
        update: (id: any) => `/sub-services/${id}`,
        show: (id: any) => `/sub-services/${id}`,
        filter_options: `/sub-services-list/filter-options`,
    },
    PROMO_CODE: {
        list: `/promo-codes`,
        create: `/promo-codes/create`,
        store: `/promo-codes`,
        edit: (id: any) => `/promo-codes/${id}/edit`,
        update: (id: any) => `/promo-codes/${id}`,
        show: (id: any) => `/promo-codes/${id}`,
        delete: (id: any) => `/promo-codes/${id}`,
        filter_options: `/promo-codes-list/filter-options`,
        usage: (id: any) => `/promo-codes/${id}/usage`,
    },

    USER: {
        list: `/users`,
        import: `/users/sanitize`,
        create: `/users/create`,
        store: `/users`,
        edit: (id: any) => `/users/${id}/edit`,
        update: (id: any) => `/users/${id}`,
        show: (id: any) => `/users/${id}`,
        delete: (id: any) => `/users/${id}`,
        filter_options: `/users-list/filter-options`,
        all: 'users-list/all',
        change_user_status: (id: any) => `/change-user-status/${id}`,
        // user_blocks: (id) => `/user-blocks/${id}`,
        // customer_class_logs: (id) => `/user-class-logs/${id}`,
        // change_class: (id) => `/user-class-logs/${id}`,
        // change_status_user_block: (id) => `/user-blocks/${id}/change-status`,
        // user_loyalty_blocks: (id) => `/user-loyalty-blocks/${id}`,
        // add_user_loyalty_block: (id) => `/user-loyalty-blocks/${id}`,
        // change_status_user_loyalty_block: (id) => `/user-loyalty-blocks/${id}/change-status`,
    },
}
