import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      // {
      //   path: "/builder",
      //   name: "builder",
      //   component: () => import("@/views/Builder.vue"),
      // },
      // {
      //   path: "/crafted/pages/profile",
      //   name: "profile",
      //   component: () => import("@/components/page-layouts/Profile.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "profile-overview",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Overview.vue"),
      //     },
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Projects.vue"),
      //     },
      //     {
      //       path: "campaigns",
      //       name: "profile-campaigns",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Campaigns.vue"),
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Documents.vue"),
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Connections.vue"),
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Activity.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/crafted/pages/wizards/horizontal",
      //   name: "horizontal-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      // },
      // {
      //   path: "/crafted/pages/wizards/vertical",
      //   name: "vertical-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      // },
      // {
      //   path: "/crafted/account",
      //   name: "account",
      //   component: () => import("@/views/crafted/account/Account.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "account-overview",
      //       component: () => import("@/views/crafted/account/Overview.vue"),
      //     },
      //     {
      //       path: "settings",
      //       name: "account-settings",
      //       component: () => import("@/views/crafted/account/Settings.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/apps/customers/getting-started",
      //   name: "apps-customers-getting-started",
      //   component: () => import("@/views/apps/customers/GettingStarted.vue"),
      // },
      // {
      //   path: "/apps/customers/customers-listing",
      //   name: "apps-customers-listing",
      //   component: () => import("@/views/apps/customers/CustomersListing.vue"),
      // },
      // {
      //   path: "/apps/customers/customer-details",
      //   name: "apps-customers-details",
      //   component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/getting-started",
      //   name: "apps-subscriptions-getting-started",
      //   component: () =>
      //     import("@/views/apps/subscriptions/GettingStarted.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/subscription-list",
      //   name: "apps-subscriptions-subscription-list",
      //   component: () =>
      //     import("@/views/apps/subscriptions/SubscriptionList.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/add-subscription",
      //   name: "apps-subscriptions-add-subscription",
      //   component: () =>
      //     import("@/views/apps/subscriptions/AddSubscription.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/view-subscription",
      //   name: "apps-subscriptions-view-subscription",
      //   component: () =>
      //     import("@/views/apps/subscriptions/ViewSubscription.vue"),
      // },
      // {
      //   path: "/apps/calendar",
      //   name: "apps-calendar",
      //   component: () => import("@/views/apps/Calendar.vue"),
      // },
      // {
      //   path: "/apps/chat/private-chat",
      //   name: "apps-private-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/group-chat",
      //   name: "apps-group-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/invite-friends",
      //   name: "modals-general-invite-friends",
      //   component: () =>
      //     import("@/views/crafted/modals/general/InviteFriends.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/view-user",
      //   name: "modals-general-view-user",
      //   component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/upgrade-plan",
      //   name: "modals-general-upgrade-plan",
      //   component: () =>
      //     import("@/views/crafted/modals/general/UpgradePlan.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/share-and-earn",
      //   name: "modals-general-share-and-earn",
      //   component: () =>
      //     import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-app",
      //   name: "modals-wizards-create-app",
      //   component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-account",
      //   name: "modals-wizards-create-account",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      // },
      // {
      //   path: "/crafted/widgets/lists",
      //   name: "widgets-list",
      //   component: () => import("@/views/crafted/widgets/Lists.vue"),
      // },
      // {
      //   path: "/crafted/widgets/statistics",
      //   name: "widgets-statistics",
      //   component: () => import("@/views/crafted/widgets/Statistics.vue"),
      // },
      // {
      //   path: "/crafted/widgets/charts",
      //   name: "widgets-charts",
      //   component: () => import("@/views/crafted/widgets/Charts.vue"),
      // },
      // {
      //   path: "/crafted/widgets/mixed",
      //   name: "widgets-mixed",
      //   component: () => import("@/views/crafted/widgets/Mixed.vue"),
      // },
      // {
      //   path: "/crafted/widgets/tables",
      //   name: "widgets-tables",
      //   component: () => import("@/views/crafted/widgets/Tables.vue"),
      // },
      // {
      //   path: "/crafted/widgets/feeds",
      //   name: "widgets-feeds",
      //   component: () => import("@/views/crafted/widgets/Feeds.vue"),
      // },
      {
      path: "profile",
      name: "admin.profile",
      component: () => import("@/views/admin/Profile.vue"),
      },
      {
        path: "/countries",
        name: "countries-list",
        component: () => import("@/views/country/CountryList.vue"),
      },
      {
        path: "/cities",
        name: "cities-list",
        component: () => import("@/views/city/CityList.vue"),
      },

      {
        path: "cities/:id",
        name: "view-city",
        component: () => import("@/views/city/ViewCity.vue"),
      },
      {
        path: "cities/create",
        name: "create-city",
        component: () => import("@/views/city/CreateCity.vue"),
      },
      {
        path: "cities/:id/edit",
        name: "edit-city",
        component: () => import("@/views/city/UpdateCity.vue"),
      },
      {
        path: "/payment_methods",
        name: "payment_methods-list",
        component: () => import("@/views/payment_methods/PaymentMethodList.vue"),
      },

      {
        path: "/orders",
        name: "orders-list",
        component: () => import("@/views/order/OrderList.vue"),
      },

      {
        path: "orders/:id",
        name: "view-order",
        component: () => import("@/views/order/ViewOrder.vue"),
      },

      {
        path: "/categories",
        name: "categories-list",
        component: () => import("@/views/category/CategoryList.vue"),
      },
      {
        path: "categories/:id",
        name: "view-category",
        component: () => import("@/views/category/ViewCategory.vue"),
      },
      {
        path: "categories/create",
        name: "create-category",
        component: () => import("@/views/category/CreateCategory.vue"),
      },
      {
        path: "categories/:id/edit",
        name: "edit-category",
        component: () => import("@/views/category/UpdateCategory.vue"),
      },

      //****************** start sub catgeories  */
      {
        path: "/sub-categories",
        name: "sub_categories-list",
        component: () => import("@/views/sub_category/SubCategoryList.vue"),
      },
      {
        path: "sub-categories/:id",
        name: "view-sub_category",
        component: () => import("@/views/sub_category/ViewSubCategory.vue"),
      },
      {
        path: "sub-categories/create",
        name: "create-sub_category",
        component: () => import("@/views/sub_category/CreateSubCategory.vue"),
      },
      {
        path: "sub-categories/:id/edit",
        name: "edit-sub_category",
        component: () => import("@/views/sub_category/UpdateSubCategory.vue"),
      },

      //*************************** products */
      {
        path: "/products",
        name: "products-list",
        component: () => import("@/views/product/ProductList.vue"),
      },
      {
        path: "products/:id",
        name: "view-product",
        component: () => import("@/views/product/ViewProduct.vue"),
      },
      {
        path: "products/create",
        name: "create-product",
        component: () => import("@/views/product/CreateProduct.vue"),
      },
      {
        path: "products/:id/edit",
        name: "edit-product",
        component: () => import("@/views/product/UpdateProduct.vue"),
      },

      //***************** tags  */
      {
        path: "/tags",
        name: "tags-list",
        component: () => import("@/views/tag/TagList.vue"),
      },
      {
        path: "tags/:id",
        name: "view-tag",
        component: () => import("@/views/tag/ViewTag.vue"),
      },
      {
        path: "tags/create",
        name: "create-tag",
        component: () => import("@/views/tag/CreateTag.vue"),
      },
      {
        path: "tags/:id/edit",
        name: "edit-tag",
        component: () => import("@/views/tag/UpdateTag.vue"),
      },

//***************** services  */
      {
        path: "/services",
        name: "services-list",
        component: () => import("@/views/service/ServiceList.vue"),
      },
      {
        path: "services/:id",
        name: "view-service",
        component: () => import("@/views/service/ViewService.vue"),
      },
      {
        path: "services/create",
        name: "create-service",
        component: () => import("@/views/service/CreateService.vue"),
      },
      {
        path: "services/:id/edit",
        name: "edit-service",
        component: () => import("@/views/service/UpdateService.vue"),
      },

//***************** services  */
      {
        path: "/sub-services",
        name: "sub-services-list",
        component: () => import("@/views/sub_service/SubServiceList.vue"),
      },
      {
        path: "sub-services/:id",
        name: "view-sub-service",
        component: () => import("@/views/sub_service/ViewSubService.vue"),
      },
      {
        path: "sub-services/create",
        name: "create-sub-service",
        component: () => import("@/views/sub_service/CreateSubService.vue"),
      },
      {
        path: "sub-services/:id/edit",
        name: "edit-sub-service",
        component: () => import("@/views/sub_service/UpdateSubService.vue"),
      },
        ////*************** promo-codes
      {
        path: "promo-codes",
        name: "promo-codes-list",
        component: () => import("@/views/promo_code/PromoCodeList.vue"),
      },
      {
        path: "promo-codes/create",
        name: "create-promo-code",
        component: () => import("@/views/promo_code/CreatePromoCode.vue"),
      },
      {
        path: "promo-codes/:id/edit",
        name: "edit-promo-code",
        component: () => import("@/views/promo_code/UpdatePromoCode.vue"),
      },
      {
        path: "promo-codes/:id",
        name: "view-promo-code",
        component: () => import("@/views/promo_code/ViewPromoCode.vue"),
      },

      //***************** subscriptions  */
      {
        path: "/news-subscriptions",
        name: "subscriptions-list",
        component: () => import("@/views/subscription/SubscriptionList.vue"),
      },
      {
        path: "/form-requests/:id/send-email",
        name: "send-email-subscription",
        component: () => import("@/views/subscription/SendEmail.vue"),
      },

      //******************* template  */
      {
        path: "/pages",
        name: "pages-list",
        component: () => import("@/views/template/pages/PageList.vue"),
      },
      {
        path: "pages/create",
        name: "create-page",
        component: () => import("@/views/template/pages/CreatePage.vue"),
      },
      {

        path: "/manage/:id",
        name: "pages.manage",
        component: () => import("@/views/template/sections/manageSection.vue"),
       },
       {
        path: "/show-section/:id",
        name: "pages.show-section",
        component: () => import("@/views/template/sections/showSection.vue"),
       },

       {
        path: "section/edit/:id",
        name: "pages.edit-section",
        component: () => import("@/views/template/sections/editSection.vue")
      },

      //***************** forms  */
      {
        path: "/forms",
        name: "forms-list",
        component: () => import("@/views/template/form/FormList.vue"),
      },
      {
        path: "forms/:id",
        name: "view-form",
        component: () => import("@/views/template/form/ViewForm.vue"),
      },
      {
        path: "forms/create",
        name: "create-form",
        component: () => import("@/views/template/form/CreateForm.vue"),
      },
      {
        path: "forms/:id/edit",
        name: "edit-form",
        component: () => import("@/views/template/form/UpdateForm.vue"),
      },
      {
        path: "form-requests",
        name: "form-requests",
        component: () => import("@/views/template/formRequest/FormRequestList.vue"),
      },
      {
        path: '/form-requests/:id/send-email',
        name: 'show-form-request',
        component: () => import("@/views/template/formRequest/showFormRequest.vue")
      },


      //***************** form feilds  */
     // {
     //    path: "/form-fields/create",
     //    name: "create-form-field",
     //    component: () => import("@/views/template/form_field/CreateField.vue"),
     //  },
      {
        path: "/form-fields/:id/edit",
        name: "edit-form-field",
        component: () => import("@/views/template/form_field/UpdateField.vue"),
      },

      //***************** General Categories  */
      {
        path: "/general-categories",
        name: "general-categories-list",
        component: () => import("@/views/general_category/GeneralCategoryList.vue"),
      },
      {
        path: "general-categories/:id",
        name: "view-general-category",
        component: () => import("@/views/general_category/ViewGeneralCategory.vue"),
      },
      {
        path: "general-categories/create",
        name: "create-general-category",
        component: () => import("@/views/general_category/CreateGeneralCategory.vue"),
      },
      {
        path: "general-categories/:id/edit",
        name: "edit-general-category",
        component: () => import("@/views/general_category/UpdateGeneralCategory.vue"),
      },

      //***************** writers  */
      {
        path: "/writers",
        name: "writers-list",
        component: () => import("@/views/writer/WriterList.vue"),
      },
      {
        path: "writers/:id",
        name: "view-writer",
        component: () => import("@/views/writer/ViewWriter.vue"),
      },
      {
        path: "writers/create",
        name: "create-writer",
        component: () => import("@/views/writer/CreateWriter.vue"),
      },
      {
        path: "writers/:id/edit",
        name: "edit-writer",
        component: () => import("@/views/writer/UpdateWriter.vue"),
      },
      //***************** news  */
      {
        path: "/camel-news",
        name: "news-list",
        component: () => import("@/views/news/NewsList.vue"),
      },
      {
        path: "camel-news/:id",
        name: "view-news",
        component: () => import("@/views/news/ViewNews.vue"),
      },
      {
        path: "camel-news/create",
        name: "create-news",
        component: () => import("@/views/news/CreateNews.vue"),
      },
      {
        path: "camel-news/:id/edit",
        name: "edit-news",
        component: () => import("@/views/news/UpdateNews.vue"),
      },
      /****** start global setting *********/
      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/general_setting/PrivacyPolicy.vue"),
      },
      {
        path: "terms-and-condition",
        name: "terms-and-condition",
        component: () => import("@/views/general_setting/TermsAndCondition.vue"),
      },
      //***************** social_media  */
      {
        path: "social-media",
        name: "social-media",
        component: () => import("@/views/general_setting/SocialMedia.vue"),
      },
      /****** end global setting *********/
      {
        path: "/users",
        name: "users-index",
        component: () => import("@/views/user/UserList.vue"),
      },
      {
        path: "/users/:id",
        name: "view-user",
        component: () => import("@/views/user/ViewUser.vue"),
      },
   
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },



  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from) => {
  store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
})

export default router;
