import {BASE_URL} from "@/store/enums/ApiEnums";

const isValidURL = function (str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
};

export const baseURL = function (uri = "") {
    if (isValidURL(uri)) return uri;
      return uri?.includes("storage") ? `${BASE_URL}/${uri}` : `${BASE_URL}/storage/${uri}`;
  }
