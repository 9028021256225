

export const checkPermission = (permission) => {
    const currentPermission = (permission+"").split("|");
    const user = JSON.parse(<string>localStorage.getItem('user'));
    const permissions = user?.permissions ?? [];
    const intersection = permissions.filter(x => currentPermission.includes(x));
    return intersection.length > 0 || (user && user.is_super);
};
export const checkFeatureDisplayed = function (item , actionCode = null) {
    if (!item.role) return true;
    const features = JSON.parse(<string>localStorage.getItem('user'))?.features ?? [];
    const feature = features.find((el) => el.code === item.role && el.feature_action_code == actionCode);
    return !!feature?.id;
};
export const commentNode = (el, vnode) => {
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined
    })
    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
   // vnode.data.directives = undefined
    if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
        el.parentNode.replaceChild(comment, el)
    }
}
export const generateId = (length) => {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}