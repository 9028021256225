import axios from 'axios';
import JwtService from "@/core/services/JwtService";
import {API_END_POINT} from "@/store/enums/ApiEnums";
class ApiAxiosService {

    public static init() {
        axios.defaults.baseURL = API_END_POINT;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Accept-Language'] = 'en';    
    
        this.setHeader();
    }


    public static setHeader() {
        axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
        // axios.defaults.headers.common["Accept"] =
        //     "application/json";

        // axios.defaults.headers.common["Content-Type"] =
        //     "multipart/form-data";

        return axios;
    }

    public static get(url: string, params: any) {
        this.setHeader();
        return axios.get(url, {params: params} );
    }

    public static post(url: string, data: any) {
        this.setHeader();
        return axios.post(url, data);
    }
    public static put(url: string, data: any) {
        this.setHeader();
        return axios.put(url, data);
    }
    public static delete(url: string, params: any) {
        this.setHeader();
        return axios.delete(url, {params: params});
    }
}

export default ApiAxiosService;
