import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {APIs} from "@/store/enums/ApiEnums";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return JSON.parse(JwtService.getUser());
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_AUTH](data) {
        this.isAuthenticated = true;
        this.user = data.admin;
        this.errors = {};
        JwtService.saveToken(data.token);
        JwtService.saveUser(this.user);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
        JwtService.destroyUser();
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post("login", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.LOGOUT]() {
        ApiAxiosService.setHeader().get('logout', {});
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return ApiService.post("register", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post("forgot_password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post("verify_token", payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.SET_FCM_TOKEN](fcm_token) {
        ApiAxiosService.post(APIs.ADMIN.set_fcm_token, {fcm_token: fcm_token});
    }
}
